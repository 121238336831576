/* eslint-disable max-len */
export const es: {
    [key: string]: string;
} = {
    '$@amount paid by @company': '$@amount pagado por @company',
    '$@amount paid with gift': '$@amount pagado con regalo',
    '$@amount paid with Let’s Eat gift': '$@amount pagado con regalo Let’s Eat',
    '$@amount paid with promo code @promocode': '$@amount pagado con còdigo promocional @promocode',
    'You pay with': 'Tu pagas con',
    'How much are you going to pay?': '¿Con cuanto vas a pagar?',
    'The entered value is less than the total value.': 'El valor ingresado es menor que el valor total.',
    '@minutes min': '@minutes min',
    '@minutes min left': '@minutes min restantes',
    'Minimum order amount not met': 'No se ha alcanzado el monto mínimo de pedido',
    'Minimum order amount: @minimumOrderAmount': 'Monto mínimo de pedido: @minimumOrderAmount',
    'Minimum order amount not met @minimum': 'No se ha alcanzado el monto mínimo de pedido @minimum',
    'Minimum order amount': 'Monto mínimo de pedido',
    'Maximum order amount reached': 'Monto maximo superado',
    'Maximum order amount: @maximumOrderAmount': 'Monto maximo de pedido: @maximumOrderAmount',
    'A verification code will be sent to you in a text message': 'Te enviaremos un mensaje de texto con un código de verificación',
    'A waiter will soon be at your table': 'Un mesero pronto estará en su mesa',
    'Accepted': 'Aceptado',
    'Account': 'Cuenta',
    'Add a Note': 'Agregue una nota',
    'Add Credit Card': 'Agregar tarjeta',
    'Add New Card': 'Agrega una nueva tarjeta',
    'Add Note': 'Agregar Nota',
    'Add Promo Code': 'Agregar código promocional',
    'Add promo code': 'Agregar código promocional',
    'Remove promo code': 'Eliminar código promocional',
    'Add to Cart': 'Agregar al carrito',
    'Add': 'Agregar',
    'American Express': 'American Express',
    'Amount': 'Cantidad',
    'Any instruction that may increase the check value will be ignored': 'Cualquier instrucción que pueda aumentar el valor de la cuenta será ignorada',
    'App': 'App',
    'Are you sure you want to sign out?': '¿Estás seguro de que deseas cerrar sesión?',
    'Are you sure you want to cancel your order?': 'Are you sure you want to cancel your order?',
    'Are you sure to continue?': '¿Estás seguro de que deseas continuar?',
    'Ask for your Let’s Eat order at the restaurant, the order will be ready at the given time.': 'Pregunta por tu orden de Let’s Eat en el restaurante y recógela a la hora establecida.',
    'By using promo code @promocode you have saved @discount on this order.': 'Usando el código @promocode haz ahorrado @discount en esta orden.',
    'Call on Waiter': 'Llamar al mesero',
    'Calling Waiter': 'Llamando al mesero',
    'Cancel Order': 'Cancelar pedido',
    'Cancel': 'Cancelar',
    'Cancelled': 'Cancelado',
    'Cancelling': 'Cancelando',
    'Cannot Access Your Location': 'No puede acceder a su ubicación',
    'Card has insufficient funds': 'La tarjeta no cuenta con fondos suficientes',
    'CARD NUMBER': 'NUMERO DE TARJETA',
    'Card payment not accepted': 'El pago con tarjeta no fue aceptado',
    'Card': 'Tarjeta',
    'CARDHOLDER’S NAME': 'NOMBRE DEL TARJETAHABIENTE',
    'Cart Items': 'Artículos del carrito',
    'Cart': 'Carrito',
    'cash': 'efectivo',
    'Cash': 'Efectivo',
    'Change Table': 'Cambiar Mesa',
    'Change': 'Cambiar',
    'Check Details Received': 'Verificar detalles recibidos',
    'Check Please!': '¡La cuenta por favor!',
    'Check your internet connection and try again. If the problem persists please contact the support.':
        'Revisa tu conexión a internet e inténtalo más tarde. Si el problema continúa favor de comunicarse a soporte técnico.',
    'Check': 'Cuenta',
    'Close': 'Cerrar',
    'Closed': 'Cerrado',
    'Pre Orders Only': 'Pre ordenes',
    'Closed Store': 'Tienda Cerrada',
    'Closing soon': 'Cierra pronto',
    'Coming Soon': 'Próximamente',
    'Company': 'Empresa',
    'Confirm Check Details': 'Confirmar detalles de la cuenta',
    'Confirm': 'Confirmar',
    'Connection issue': 'Error al descargar restaurantes',
    'CONTAINS:': 'CONTIENE:',
    'Could not download menu': 'No se pudo descargar el menú',
    'Credit Card': 'Tarjeta',
    'Credit Cards': 'Tarjetas de Crédito/Débito',
    'Credits added': 'Créditos añadidos',
    'Credits bought': 'Créditos comprados',
    'Credits per meal': 'Creditos por comida',
    'Credits refunded': 'Créditos reembolsados',
    'Credits removed': 'Créditos removidos',
    'Credits': 'Créditos',
    'CVC/CVV': 'CVC/CVV',
    'Day': 'Día',
    'day': 'día',
    'days': 'dias',
    'Time': 'Hora',
    'Deadline before this application won’t work anymore: 20 june': 'Fecha límite, 20 de junio. Posterior a esto, está versión no será funcional',
    'DELIVERY @time': 'ENTREGA @time',
    'Delivery to': 'Entregar en',
    'Delivery': 'Envío a domicilio',
    'DELIVERY': 'ENVÍO',
    'Free Delivery.': 'Envio Gratis.',
    'Didn’t receive the code, try again?': 'No recibí el código, ¿Intentar otra vez?',
    'Discount': 'Descuento',
    'E-mail Support': 'Soporte de correo electrónico',
    'Eat Here': 'Comer aquí',
    'Edit Credit Card': 'Edita tarjeta',
    'Email': 'Correo electrónico',
    'Enter promo code': 'Introducir código promocional',
    'Enter the table number found at your table': 'Ingrese el número que se encuentra en su mesa',
    'Enter verification code': 'Ingrese el código de verificación',
    'Enter password': 'Ingresa tu contraseña',
    'Enter your credit card details': 'Ingrese la información de su tarjeta',
    'Enter your email': 'Ingrese su correo electrónico',
    'Enter your mobile number': 'Agregue su número de teléfono',
    'Enter your name': 'Escriba su nombre',
    'Enter your promo code': 'Ingresa tu código de promoción',
    'Everything above the credits paid by @company will be charged from your personal payment method.':
        'Los costos que sobrepasen la cantidad de créditos recibidos por @company serán deducidos de tu método de pago personal seleccionado.',
    'EXPIRY': 'FECHA DE EXPIRACIÓN',
    'EXPLORE WITHOUT ACCOUNT': 'EXPLORAR SIN CUENTA',
    'Failed to call on waiter, please try again': 'No se pudo llamar al mesero, por favor intente de nuevo',
    'Failed to Call Waiter': 'Error al llamar al mesero',
    'Failed to cancel order, please try again.': 'No se pudo cancelar el pedido, por favor intente de nuevo.',
    'Failed to download menu': 'Error al cargar menú',
    'Failed to download promotion': 'Error al cargar promoción',
    'Failed to locate your position cause of ': 'Error al buscar su ubicación a causa de ',
    'Failed to locate your position': 'Error al buscar su ubicación',
    'Failed to open WhatsApp. Please try another support chanel.': 'Error al abrir WhatsApp. Por favor, intente con otro canal de soporte.',
    'Failed to save, please try again.': 'Error al guardar, favor de intentarlo nuevamente',
    'Failed to send order, please try again': 'No se pudo enviar el pedido, intente de nuevo',
    'Failed to send sending check details, please try again': 'Error al enviar la cuenta, intente de nuevo por favor',
    'Failed to sign in/sign up, please contact support.': 'No fue posible registrarse / iniciar sesión, comuníquese con soporte técnico',
    'Failed to sign in/sign up, please try again.': 'No fue posible registrarse / iniciar sesión, favor de intentarlo de nuevo',
    'Failed to sign in/sign up, verify your account has a password. Otherwise, sign in with code and create one.':
        'No fue posible registrarse / iniciar sesión, confirma que tu cuenta tenga contraseña creada. De lo contrario, ingresa con código para crear una.',
    'Failed to verify code, please try again.': 'Error al verificar el código, favor de intentarlo nuevamente',
    'First Name': 'Nombre',
    'Fr': 'Vi',
    'Free': 'Gratis',
    'FRI': 'VIE',
    'FULL NAME': 'NOMBRE COMPLETO',
    'Full Name': 'NOMBRE COMPLETO',
    'Gift from @company': 'Regalo de @company',
    'Gift from Let’s Eat': 'Regalo de Let’s Eat',
    'Gift': 'Regalo',
    'Have any questions?': '¿Tienes alguna pregunta?',
    'Help': 'Ayuda',
    'hour': 'hora',
    'hours': 'horas',
    'How do you want to pay?': '¿Cómo deseas pagar?',
    'How do you want your order?': '¿Cómo quieres tu pedido?',
    'How do you prefer to confirm your user?': '¿Por dónde prefieres confirmar tu usuario?',
    'How does it work?': '¿Como funciona?',
    'It does’nt seem that we can deliver to you area yet. If this is a mistake send us a WhatsApp.':
        'Lo sentimos, por el momento no contamos con entregas en tu empresa. Comunícate con nosotros para comenzar el servicio.',
    'Last Name': 'Apellido',
    'Later': 'Más tarde',
    'Let’s Eat credits are the currency of Let’s Eat. 1 credit equals to $1 MXN. Get Credits by sharing your reference code!':
        'Los Créditos son una moneda de Let’s Eat. $1 Crédito equivale a $1 MXN. Gana Créditos compartiendo tu código de referido!',
    'Let’s Eat Credits': 'Let’s Eat Créditos',
    'Locating nearest restaurants': 'Localizando los restaurantes más cercanos',
    'Make sure to pay the check before leaving!': '¡Asegúrese de pagar la cuenta antes de irse!',
    'Master Card': 'Master Card',
    'MAX': 'MAX',
    'Maximum discount @maxdiscountamount.': 'Descuento máximo @maxdiscountamount.',
    'Meal days': 'Dias de comidas',
    'Meal hours': 'Horarios de comidas',
    'Meal Program': 'Programa de Comidas',
    'Meals per week': 'Comidas por semana',
    'Menu': 'Menú',
    'MIN': 'MIN',
    'Minimum order @minorderamount.': 'Pedido mínimo @minorderamount.',
    'minute': 'minuto',
    'minutes': 'minutos',
    'MM/YY': 'MES/AÑO',
    'Mo': 'Lu',
    'MON': 'LUN',
    'MONTH/YEAR': 'MES/AÑO',
    'NAME': 'NOMBRE',
    'Nearby Promotions': 'Promociones Cercanas',
    'Nearby Restaurants': 'Restaurantes Cercanos',
    'Network error, please check your connection.': 'Error de red, compruebe su conexión.',
    'Next': 'Siguiente',
    'No credit card added': 'No hay tarjeta registrada en su cuenta',
    'No items matches your search': 'Ningún elemento coincide con su búsqueda',
    'No orders placed yet': 'Sin pedidos aún',
    'No restaurants matches your search': 'Ningún restaurante coincide con tu búsqueda',
    'No transactions found': 'No se encontraron transacciones',
    'No valid promotion found': 'La promoción ingresada no es válida',
    'No valid promotion for your cartItems': 'La promoción no es válida para los productos de tu carrito',
    'No': 'No',
    'Not available at your company': 'No disponible en tu empresa',
    'Not available for the moment': 'Por el momento no está disponible',
    'Not available': 'No disponible',
    'Not in Restaurant': 'No se encuentra en el restaurante',
    'Not signed in': 'No ha iniciado sesión',
    'now': 'ahora',
    'Ok': 'De acuerdo',
    'Ongoing Order': 'Pedido en curso',
    'Ongoing Orders': 'Pedidos en curso',
    'Oops!': '¡Ups!',
    'ORDER BY @time': 'ORDENA HASTA @time',
    'Order Cancelled': 'Pedido Cancelado',
    'Order paid': 'Orden pagada',
    'Order Rejected': 'Pedido Rechazado',
    'Order was cancelled': 'La orden ha sido cancelada',
    'Order was rejected': 'La orden ha sido rechazada',
    'Order': 'Ordenar',
    'Ordered': 'Ordenado',
    'Ordering Failed': 'Falló el pedido',
    'Ordering': 'Ordenando',
    'Orders Status': 'Estado de pedidos',
    'Orders': 'Pedidos',
    'Open': 'Abierto',
    'Other': 'Otro',
    'Paid at delivery': 'Pago en entrega',
    'Paid by @company': 'Pagado por @company',
    'Paid in store': 'Pagado en establecimiento',
    'Paid with cash': 'Pagado en efectivo',
    'Paid with card': 'Pagado con tarjeta',
    'Paid with card, refunded as credits': 'Pagado con tarjeta, reembolsado en créditos',
    'Paid with card, refunded to card': 'Pagado con tarjeta, reembolsado a tu tarjeta',
    'Paid with payroll': 'Pagado con nómina',
    'Paid with payroll, refunded': 'Pagado con nómina, reembolsado',
    'Pay at delivery': 'Pago al entregar',
    'Pay in store': 'Pago en tienda',
    'Pay with cash': 'Pagar con efectivo',
    'Pay with payroll': 'Pago con nómina',
    'Paying at delivery': 'Pago al entregar',
    'Paying in store': 'Pagando en establecimiento',
    'Payment': 'Pago',
    'Payment link failed': 'Enlace de pago rechazado',
    'Payroll Payment': 'Pago de Nómina',
    'Phone Number': 'Número de teléfono',
    'Pick up': 'Pick up',
    'PICK UP': 'PICK UP',
    'Pick-up in': 'Pick-up en',
    'Picked Up': 'Recogido',
    'Pickup Station': 'Estación de entrega',
    'Pickup Time': 'Hora para recoger',
    'Pickup': 'Recoger',
    'Planned Order': 'Orden agendada',
    'Please try again later': 'Intenta de nuevo más tarde',
    'Please try again later.': 'Intenta de nuevo más tarde.',
    'Please have the exact change ready. Delivery drivers carry limited change and may not always have enough change. Your delivery will be canceled if you cant pay the full amount. Please reward your driver with a tip for outstanding service.':
        'Por favor tener el cambio justo listo. Los repartidores llevan consigo cambio limitado y no siempre tienen el cambio suficiente. Tu delivery será cancelado si no puedes pagar el monto total. Por favor recompensa al repartidor con propina por el servicio.',
    'Pre-order': 'Pre-orden',
    'Press back again to leave restaurant': 'Presione de nuevo para salir del restaurante',
    'Problem in the restaurant': 'Problema en el restaurante',
    'Product Discount': 'Descuento del producto',
    'Promotions discount': 'Descuento de promociones',
    'Promo code @promocode': 'Código promocional @promocode',
    'Promo Code': 'Código promocional',
    'Promotions': 'Promociones',
    'Promotions and more': 'Promos y más',
    'Pull to refresh': 'Deslice hacia abajo para actualizar',
    'Pull to try again': 'Desliza hacia abajo para intentar otra vez',
    'Ready in @minutes min': 'Listo en @minutes min',
    'Receive @discount credits on every order.': 'Recibe @discount en créditos en todos tus pedidos.',
    'Receive @discount credits on first order.': 'Recibe @discount en créditos en tu primer pedido.',
    'Receive @discount credits on this order.': 'Recibe @discount en créditos en este pedido.',
    'Received': 'Recibido',
    'Refunded credits': 'Créditos reembolsados',
    'Refunded to card': 'Reembolsado a tu tarjeta',
    'Refunded to payroll': 'Reembolsado a tu nómina',
    'Refunded': 'Reembolsado',
    'Register your company': 'Registra tu empresa',
    'Rejected': 'Rechazado',
    'Remember my choice': 'Recordar mi elección',
    'Remove Credit Card': 'Eliminar tarjeta',
    'REQUIRED': 'REQUERIDO',
    'Restaurant pinned at top of list': 'Restaurante fijado al inicio de la lista',
    'Restaurants close by for Pickup': 'Restaurantes Cercanos Para Recoger',
    'Restaurants': 'Restaurantes',
    'Sa': 'Sa',
    'Save @discount on every order.': 'Ahorra @discount en todos tus pedidos.',
    'Save @discount on first order.': 'Ahorra @discount en tu primer pedido.',
    'Save @discount on this order.': 'Ahorra @discount en este pedido.',
    'SCAN CARD WITH CAMERA': 'ESCANEAR TARJETA CON LA CÁMARA',
    'Search': 'Buscar',
    'second': 'segundo',
    'seconds': 'segundos',
    'Keep shopping': 'Continuar comprando',
    'Delivery address': 'Dirección de entrega',
    'Pickup address': 'Dirección para recoger',
    'Select Card': 'Seleccionar Tarjeta',
    'Select delivery method': 'Selecciona modo de entrega',
    'Select Delivery Point': 'Selecciona punton de entrega',
    'Select delivery time': 'Selecciona hora de entrega',
    'Select payment method': 'Selecciona método de pago',
    'Select Payment Method': 'Seleccionar método de pago',
    'Select Pickup Time': 'Escoger hora para recoger',
    'Select Table': 'Seleccionar mesa',
    'Table @table': 'Mesa @table',
    'Select time for pre-ordering': 'Seleccione el tiempo para pre-ordenar',
    'Select Tip': 'Seleccionar propina',
    'Select': 'Seleccionar',
    'Sending Check Details Failed': 'Se produjo un error al enviar los detalles de la cuenta',
    'Sending Check Details': 'Enviando detalles de la cuenta',
    'Sent': 'Enviado',
    'Served': 'Servido',
    'Sign In / Sign Up': 'Iniciar sesión / Registrar',
    'SIGN IN BY NUMBER': 'INGRESAR CON NÚMERO',
    'Sign out': 'Cerrar sesión',
    'Signed in with': 'Iniciado sesión con',
    'Since you have used promo code @promocode you will receive @discount when this order is completed.':
        'Por haber usado el código @promocode recibirás @discount cuando la orden se haya completado.',
    'Skip this step': 'Saltate este paso',
    'Sold out': 'Artículo agotado',
    'Something went wrong when ordering. Please try again.': 'Lo sentimos, ha ocurrido un error en su orden. Favor de intentarlo nuevamente.',
    'Something went wrong. Check your internet connection and try again. If the problem persists please contact the support.':
        'Hubo un error. Revisa tu conexión a internet e inténtalo más tarde. Si el problema continúa favor de comunicarse a soporte técnico.',
    'Something went wrong. If the problem persists please contact the support.': 'Hubo un error. Si el problema persiste por favor contáctanos.',
    'Something went wrong': 'Hubo un error',
    'Sorry the dish is no longer available to order.': 'Lo sentimos, el platillo seleccionado ya no se encuentra disponible.',
    'Sorry': 'Disculpa',
    'Sorry, you entered an invalid security code (the last 3 digits on the backside of your card). Please remove your card and try again.':
        'Lo sentimos, código de seguridad invalido. Favor de verificarlo e intentarlo nuevamente.',
    'Store': 'Establecimiento',
    'Su': 'Do',
    'Subtotal': 'Subtotal',
    'Table': 'Mesa',
    'Take Away': 'Para Recoger',
    'Temporarily Offline': 'Temporalmente sin servicio',
    'Th': 'Ju',
    'Thanks for your order! You’ve just saved 15 minutes that you can spend on something more fun this beautiful day.':
        '¡Gracias por ordenar con nosotros! ¡Se acaba de ahorrar 15 minutos que puede aprovechar en algo más productivo en este hermoso día!',
    'The amount can either be reimbursed through Let’s Eat Credits which can be used directly on your next purchase. Or, it can be reversed to your card but it might take up to 10 banking days.':
        'El monto puede ser reembolsado a través de créditos Let’s Eat que se pueden utilizar en su próxima compra. O bien, se puede hacer un reembolso a su tarjeta, puede demorar hasta 10 días hábiles.',
    'The amount has been reimbursed as Let’s Eat Credits which can be used directly on your next purchase.':
        'El monto ha sido reembolsado como Créditos Let’s Eat que se pueden utilizar en su próxima compra.',
    'The amount has been reversed to your card. It might take up to 10 banking days before it’s visible on your account.':
        'El monto se ha reembolsado a su tarjeta. Puede tomar hasta 10 días hábiles antes de que sea visible en su cuenta.',
    'The verification code was sent by WhatsApp to @mobileNumber': 'El código de verificación fue enviado por WhatsApp a @mobileNumber',
    'The verification code was sent by email to @mobileNumber': 'El código de verificación fue enviado por correo electrónico a @mobileNumber',
    'The verification code was sent to @mobileNumber': 'El código de verificación fue enviado a @mobileNumber',
    'The online store is temporarily out of service but you can still explore the menu': 'La tienda en linea esta temporalmente fuera de servicio pero aun puedes explorar el menu',
    'The online store is closed but you can still explore the menu': 'La tienda en linea esta cerrada pero aun puede explorar el menu',
    'The kitchen is verifying your order. You will receive a notification once it’s been accepted. It is possible to cancel your order before it is accepted.':
        'La cocina está verificando tu orden. Te llegará una notificación en cuanto sea aceptada. Es posible cancelar tu orden antes de que sea aceptada.',
    'The price is incorrect': 'El precio es incorrecto',
    'The promo code cannot be used with the selected payment method.': 'El código promocional no puede ser utilizado con el método de pago seleccionado.',
    'The restaurant is coming soon. Please try another restaurant.': 'El restaurante aún no está activo. Por favor, intente con otro restaurante',
    'The restaurant is not available at the moment. Please try another restaurant.': 'El restaurante no está disponible por el momento. Por favor intente con otro restaurante.',
    'The restaurant is verifying your order. You will receive a notification once it’s been accepted. It is possible to cancel your order before it is accepted.':
        'El restaurante está verificando la orden. ¡Te llegará una notificación en cuanto sea aceptada! Es posible cancelar la orden antes de que sea aceptada.',
    'The restaurant was not able to take your order right now. Please try again later or choose a different time for pickup.':
        'El restaurante no pudo tomar su pedido. Por favor intente más tarde o escoja otra hora para recoger.',
    'The restaurant will accept your order @preparationtime minutes before the specified time': 'El restaurante aceptará tu pedido @preparationtime minutos antes de la hora establecida',
    'The restaurant will soon start preparing your order.': 'El restaurante empezará pronto a preparar su pedido',
    'The system is being upgraded. Please try again later.': 'El sistema está siendo actualizado. Intenta de nuevo más tarde.',
    'The waitress will soon be at your table with the check': 'El mesero estará pronto en su mesa con la cuenta',
    'There’s a new version of Let’s Eat available. Download it on the button below and make sure to delete the old application.':
        'Hay una nueva versión disponible de Let’s Eat. Descárgala en el botón a continuación y asegúrate de eliminar la versión anterior.',
    'This card has insufficient funds to complete the purchase. If this is not correct please contact the Card Payments Division of your bank.':
        'Esta tarjeta no tiene fondos suficientes para completar la compra. Si esto no es correcto, comunícate con tu banco o con el proveedor de tu tarjeta.',
    'THU': 'JUE',
    'Time for an update': 'Actualización requerida',
    'Tip': 'Propina',
    'To add a promo code you must first select pay in app': 'Para agregar un código promocional porfavor eliga la opción de pagar en la app',
    'To select pay in store you must first remove the promo code': 'Para pagar en sitio, porfavor remueva el código promocional',
    'Today': 'Hoy',
    'Tomorrow': 'Mañana',
    'Total': 'Total',
    'Transactions': 'Transacciones',
    'Try another day': 'Intenta seleccionar otro día',
    'Tu': 'Ma',
    'TUE': 'MAR',
    'Type to search for restaurants': 'Escribe para buscar restaurante',
    'Type to search the menu': 'Escriba para buscar en el menú',
    'Type your special instruction\n(extra lemon, no ice)': 'Escriba sus indicaciones adicionales\n(extra limón, sin hielo)',
    'Type your special instruction\n(extra sauce, no onions)': 'Escriba sus indicaciones adicionales\n(salsa extra, sin cebollas)',
    'Type your special instruction': 'Escriba sus indicaciones adicionales',
    'Add an instruction': 'Agrega alguna instrucción',
    'Unfortunately, your order has ben declined because the restaurant did not have the dish at the moment, try again!':
        'Desafortunadamente su orden ha sido rechazada. El restaurante no cuenta con el platillo seleccionado por el momento. Seleccione algo diferente e intentelo nuevamente!',
    'Unfortunately, there are no products available on our menu at this time': 'Lamentablemente, en este momento no hay productos disponibles en nuestro menú',
    'Update Available': 'Actualización Disponible',
    'Update': 'Actualizar',
    'Valid @between': 'Válido @between',
    'Valid @date': 'Válido @date',
    'Valid @fromdate - @todate': 'Válido @fromdate - @todate',
    'Valid from @fromdate': 'Válido desde @fromdate',
    'Valid to @todate': 'Válido hasta @todate',
    'Verified!': 'Verificado!',
    'Verified': 'Verificado',
    'Verifying': 'Verificando',
    'Visa': 'Visa',
    'Waiter Called': 'Mesero contactado',
    'We love bringing people together. Register your company and start ordering with your teammates.':
        'La comida es mejor cuando se comparte. Registra tu empresa y comienza a pedir junto con tus compañeros de trabajo.',
    'We need your email to be able to verify your identity and give you support': 'Para poder brindar soporte, es necesario verificar su identidad a través de su correo electrónico',
    'We need your name to be able to hand out your orders': 'Necesitamos su nombre para poder entregarle su pedido',
    'We': 'Mi',
    'WED': 'MIÉ',
    'We’re sorry about any issue with your order or experience. Describe the occurred problem and we will provide assistance ASAP.':
        'Nos disculpamos por cualquier inconveniente que haya tenido al usar nuestra aplicación. Por favor, describa su problema a continuación y trataremos de solucionarlo a la brevedad posible.',
    'We’re sorry, we’re having difficulty communicating with your bank. At the moment we can not process your request. Please try again later.':
        'Lo sentimos, estamos teniendo dificultades para comunicarnos con su banco. Por el momento no podemos procesar su solicitud. Por favor inténtalo más tarde.',
    'What do you want?': 'Qué desea?',
    'WhatsApp Support': 'Soporte de WhatsApp',
    'When do you want to pickup your order?': '¿Cuándo quiere recoger su pedido?',
    'When do you want your order?': '¿Cuándo quieres tu pedido?',
    'Wrong verification code entered, please try again.': 'Código de verificación incorrecto, favor de intentarlo nuevamente.',
    'Wrong password entered, please try again.': 'Contraseña incorrecta, favor de intentarlo nuevamente.',
    'Yes': 'Si',
    'You account is verified for ordering': 'Su cuenta está verificada para ordenar',
    'You are currently not enabled to pay with your payroll and not connected to any meal program.': 'Actualmente no estas dado de alta en ningún sistema de pago por nómina o programa de comidas',
    'You have been enabled to pay with your payroll.': 'La opción de pago por nómina ha sido habilitada',
    'You have cancelled your order.': 'Ha cancelado su pedido.',
    'You have not ordered anything yet': 'Aún no ha pedido nada',
    'You have to select a delivery point': 'Debes seleccionar un punto de entrega',
    'You have unpaid checks': 'Tiene cuentas sin pagar',
    'You must order for at least @minorderamount when using this promo code': 'Debes ordenar un mínimo de @minorderamount para utilizar este código promocional',
    'You need sign in before you can add a credit card': 'Necesita iniciar sesión antes de agregar una tarjeta',
    'You need to add a credit card to be able to pay directly from the app': 'Necesita agregar una tarjeta (crédito/débito) para poder pagar a través de la aplicación',
    'You need to sign in / sign up to be able to order to your office.': 'Debes iniciar sesión./ Crea una cuenta para poder ordenar a tu oficina.',
    'You need to sign in to be able to order': 'Necesita iniciar sesión para poder ordenar',
    'You need to sign in to be able to see your credits': 'Inicia sesión para ver tu crédito disponible',
    'You need to sign in to be able to see your orders': 'Inicia sesión para ver tus pedidos',
    'You tried too many times! Wait some time before trying again.': 'Has sobrepasado el límite de intentos, favor de esperar un poco antes de intentar registrarse / iniciar sesión nuevamente.',
    'Your company will deduct the total of your orders from your next payroll. For more information send us an email to soporte@letseat.mx':
        'Tu empresa descontará el total de tus pedidos en tu próxima nómina. Para más información puedes enviarnos un correo a soporte@letseat.mx.',
    'Your Credits': 'Tus Créditos',
    'Your food is waiting for you.': 'Tu comida te espera.',
    'Your food will be be delivered to your Pick-up Station.': 'Tu comida será entregada en el punto de entrega establecido.',
    'Your order has been accepted': 'Su pedido ha sido aceptado',
    'Your order has been canceled, the amount charged will be reimbursed in the next days. We offer you an apology for the inconvenience.':
        'Tu orden ha sido cancelada, el monto cargado será reembolsado en los proxímos días. Te ofrecemos una disculpa por los inconvenientes.',
    'Your order has been cancelled': 'Su pedido ha sido cancelado',
    'Your order has been picked up': 'Su pedido ha sido recogido',
    'Your order has been rejected': 'Su pedido ha sido rechazado',
    'Your order has been rejected, the amount charged will be reimbursed in the next days. We offer you an apology for the inconvenience.':
        'Tu orden ha sido rechezada, el monto cargado será reembolsado en los proxímos días. Te ofrecemos una disculpa por los inconvenientes.',
    'Your order has been sent': 'Tu pedido ha sido enviado',
    'Your order has been served': 'Su pedido ha sido servido',
    'Your order is being prepared': 'Tu orden está siendo preparada',
    'Your payment info is encrypted and protected by Operaciones en Linea, Conekta and Stripe': 'Tu información de pago está encriptada y protegida por Operaciones en Línea, Conekta y Stripe',
    'Your request was declined by your bank. Please contact the Card Payments Division of your bank or try with another card.':
        'Su solicitud fue rechazada por su banco. Comuníquese con la División de Pagos con Tarjeta de su banco o intente con otra tarjeta.',
    'Your version of the app is outdated. Please visit App Store to download the latest version.': 'Tienes una versión anterior de la aplicación. Visita el App Store para descargar la versión nueva.',
    'Your version of the app is outdated. Please visit Play Store to download the latest version.':
        'Tienes una versión anterior de la aplicación. Visita la Play Store para descargar la versión nueva.',
    'your.email@example.com': 'su.correoelectronico@ejemplo.com',

    'Thank you!': '¡Gracias!',
    'How was your experience?': '¿Cómo fue tu experiencia?',
    'What were the reasons?': '¿Cuáles fueron los motivos?',
    'Send': 'Enviar',
    'How much did you have in mind?': '¿Cuánto tienes en mente?',

    'WhatsApp not installed. Please install WhatsApp and try again.': 'Porfavor instala Whatsapp e intenta nuevamente.',

    'The promo code cannot be used for delivery orders.': 'El código promocional no aplica para envios.',
    'The promo code cannot be used for pick up orders.': 'El código promocional no aplica para recoger en restaurante.',
    'The promo code cannot be used when @ordertype is selected.': 'El código promocional no puede ser utilizado cuando @ordertype está seleccionado.',
    'You must add a credit card to use this promo code.': 'Para utilizar el código promocional, debe de agregar una tarjeta de crédito.',
    'You must order for at least @amount to use this promo code.': 'El código promocional es válido en compras mayores a @amount.',

    'Enter your dynamic CVV number': 'Ingresa tu número de CVV dinámico',
    'You need to generate a dynamic CVV number in your Sodexo Club app to be able to order. Open your Sodexo Club app with the link below and click on the button "See CVV" to generate a dynamic CVV code.':
        'Debes encontrar el número CVV dinámico en tu aplicación de Sodexo Club para poder realizar un pedido. Abre tu aplicación Sodexo Club en el siguiente enlace y haz clic en el botón "Ver CVV" para generar un código CVV dinámico.',
    'You need to generate a dynamic CVV number in your Sodexo Club app to be able to order. Open your Sodexo Club app and click on the button "See CVV" to generate a dynamic CVV code.':
        'Debes encontrar el número CVV dinámico en tu aplicación de Sodexo Club para poder realizar un pedido. Abre tu aplicación Sodexo Club y haz clic en el botón "Ver CVV" para generar un código CVV dinámico.',
    'Return to Let’s Eat and enter the CVV code generated and click Next.': 'Regresa a Let’s Eat e ingresa el código CVV generado y haz clic en Siguiente.',
    'Open Sodexo App': 'Abrir aplicación Sodexo',
    'Ready for pickup': 'Listo para recoger',

    'Monday': 'Lunes',
    'Tuesday': 'Martes',
    'Wednesday': 'Miércoles',
    'Thursday': 'Jueves',
    'Friday': 'Viernes',
    'Saturday': 'Sábado',
    'Sunday': 'Domingo',

    'Opens at': 'Abre a las',
    'Closes at': 'Cierra a las',

    'Order delivery to your company. If your company is not listed, contact us at hola@lesteat.mx': 'Envíos a tu empresa. Si tu empresa no aparece en la lista, contáctanos en hola@letseat.mx',
    'Pre-order and pick up from nearby restaurants. Skip the lines!': 'Pre-ordena y recoge en restaurantes cercanos. ¡Olvídate de las filas!',
    'Previous': 'Anterior',
    'Got it!': '¡Entendido!',
    'More orders below': 'Otras pedidos abajo',
    'Until': 'Antes de',
    'The food arrived late': 'La comida llegó tarde',

    'The promo code cannot be used for company orders.': 'El código promocional no puede utilizarse para pedidos a compañías.',
    'Delivery in': 'Envío en',
    'The store is verifying your order. You will receive a notification once it’s been accepted. It is possible to cancel your order before it is accepted.':
        'El comercio está revisando tu pedido, recibirás una notificación cuando sea aceptada. Puedes cancelar tu pedido antes de ser aceptado',
    'The store was not able to take your order right now. Please try again later or choose a different time for delivery.':
        'El comercio no pudo aceptar tu pedido. Por favor intenta de nuevo más tarde o escoge otra hora de envío',
    'The order will be delivered at the given time.': 'El pedido será enviado a la hora especificada.',
    'Your order has been delivered': 'Tu pedido ha sido enviado',
    'Delivered': 'Entregado',
    'Estimated time': 'Tiempo estimado',
    'Estimated delivery @minutes min': 'Tiempo estimado de envío @minutes min.',
    'Estimated delivery (@minutes min)': 'Tiempo estimado (@minutes min)',
    'Select delivery address': 'Selecciona una dirección de envío',
    'You forgot to tell us where to deliver': 'Olvidaste decirnos a donde entregar',
    'You forgot to add your credit card': 'Olvidaste agregar tu tarjeta de crédito',

    'Select one of the categories above': 'Selecciona una categoría',
    'Order delivery to your address.': 'Envío a tu dirección',
    'My Addresses': 'Mis direcciones',
    'You need sign in before you can add an address': 'Necesitas ingresar a tu cuenta para poder agregar una dirección',
    'You need to add an address to be able to create a delivery order': 'Necesitas agregar una dirección de envío antes de realizar un pedido',
    'Add Address': 'Agregar dirección de envío',
    'Enter your address': 'Agregar tu dirección',
    'Enter address': 'Agregar dirección',
    'Loading google maps...': 'Cargando google maps...',
    'Street address and building number': 'Calle y número',
    'Staircase / apartment / floor / other': 'Departamento / piso / otro',
    'Instructions for carrier': 'Instrucciones para el repartidor',
    'Is this your address?': '¿Esta es tu dirección?',
    'Start typing to search for other addresses': 'Empieza a escribir para buscar otra dirección',
    'Start typing to search for your address': 'Empieza a escribir para buscar tu dirección',
    'Pick up in Store': 'Recoger en el comercio',
    'Where should we deliver?': '¿A dónde te lo enviamos?',
    'Add New Address': 'Agregar nueva dirección',
    'Estimated delivery': 'Entrega estimada',
    'Between @minutes minutes': 'Entre @minutes minutos',

    'Your address is too far away': 'Tu dirección está demasiado lejos',
    'Too Far Away': 'Demasiado Lejos',
    'Deliver To': 'Entregar En',

    'Select Address': 'Selecciona dirección',
    'Deliver to': 'Entregar a',
    'Searching for driver': 'Buscando repartidor',
    'Driver': 'Repartidor',

    'Name': 'Nombre',
    'Note': 'Nota',

    'Address': 'Domicilio',

    'Hola @restaurantName! Soy *@customerName* y quiero hacer el siguiente pedido hacia *@customerAddress*:\n\n@orderItems\n\n*Total: @total*\n\nMuchas gracias!':
        'Hola @restaurantName! Soy *@customerName* y quiero hacer el siguiente pedido hacia *@customerAddress*:\n\n@orderItems\n\n*Total: @total*\n\nMuchas gracias!',
    'Hola @restaurantName! Soy *@customerName* y quiero hacer el siguiente pedido para llevar y en la sucursal de @restaurantStreet:\n\n@orderItems\n\n*Total: @total*\n\nMuchas gracias!':
        'Hola @restaurantName! Soy *@customerName* y quiero hacer el siguiente pedido para llevar y en la sucursal de @restaurantStreet:\n\n@orderItems\n\n*Total: @total*\n\nMuchas gracias!',

    'Order by WhatsApp': 'Enviar por WhatsApp',

    'Menu not found': 'No se encontró el menu',
    'Store not found': 'No se encontró la tienda',
    'Please check your url': 'Verifique el url',
    'Url has moved!': 'Url ha cambiado!',
    'Please use following url instead': 'Intente el siguiente url',
    'Select Store': 'Seleccionar tienda',
    'Loading': 'Cargando',
    'Change Store': 'Cambiar Tienda',
    'Failed to add card': 'Error al agregar tarjeta',
    'Failed to sign in/sign up, please try again. If the problem persists please contact the support.':
        'No fue posible registrarse / iniciar sesión, favor de intentarlo de nuevo. Si el problema continúa favor de comunicarse a soporte técnico.',
    'Mobile Number': 'Número de teléfono',
    'Verification Code': 'Código de verificación',

    'Must be a phone number': 'Must be a phone number',
    'The code was sent in a SMS to @mobileNumber': 'El código fue enviado en un SMS a @mobileNumber',

    'Send Verification Code': 'Enviar código de verificación',
    'Verify': 'Verificar',
    'Pay with card': 'Pagar con tarjeta',
    'Pay with card on delivery': 'Pagar con tarjeta en la entrega',
    'Your order is being prepared/delivered': 'Tu pedido se está preparando/entregando',
    'Search Products': 'Buscar Productos',
    'Welcome to @restaurantChainName!': 'Bienvenido a @restaurantChainName!',
    'Select the nearest branch': 'Selecciona la sucursal más cercana',
    'Select a location': 'Selecciona una dirección',
    'Further than the delivery radius': 'Fuera del rango de entrega',
    'Within delivery radius': 'Dentro del rango de entrega',
    'Approximate distance, within the delivery range': 'Distancia aproximada, dentro del rango de entrega',
    'Only @prefix mobile numbers are permitted': 'Solo números de teléfono con @prefix están permitidos',
    'PAY': 'PAGAR',
    'Restaurant': 'Restaurante',
    'Restaurant Opening Hours': 'Horario de Atención',
    'Sorry, card payment method is not allowed. Please try to pay with cash.': 'Lo sentimos, los pagos con tarjeta no están habilitados de momento. Por favor intenta pagar con efectivo.',

    '@firstName, thanks for your order': '@firstName, gracias por tu orden',
    'Order created': 'Orden creada',
    'Order accepted': 'Orden aceptada',
    'Order on the way': 'Orden enviada',
    'Order delivered': 'Orden entregada',
    'Order rejected': 'Orden rechazada',

    'Show Direction - @direction': 'Mostrar dirección - @direction',
    'Call merchant - @restaurantName': 'Llamar restaurante - @restaurantName',
    'Call driver - @driverName': 'Llamar repartidor - @driverName',
    'Your order': 'Tu orden',
    'FOOD_WAS_COLD': 'La comida estaba fria',
    'MISSING_OR_INCORRECT_ITEMS': 'Elementos faltantes o incorrectos',
    'FOOD_WAS_NOT_PREPARED': 'La comida no estaba preparada',
    'FOOD_ARRIVED_LATE': 'La comida llegó tarde',
    'CONFUSED_PICK_UP_PLACE': 'Lugar de recogida confuso',
    'BAD_SERVICE': 'Mala atención al cliente',
    'Thank you for sending this survey, now you can close this page.': 'Gracias por contestar esta encuesta, ya puedes cerrar la pagina.',
    'This survey has been answered!': '¡Esta encuesta ya se ha contestado!',
    'Your feedback helps us to improve!': '¡Tu opinión nos ayuda a mejorar!',
    'What do you enjoy most about @restaurantName?': '¿Qué es lo que más disfrutas de @restaurantName?',
    'PUNCTUALITY': 'Puntualidad',
    'APPLICATION': 'Facilidad de la aplicación',
    'FOOD': 'Sabor de la comida',
    'PRESENTATION': 'Presentación',
    'SERVICE': 'Buena atención a cliente',

    'Add a Custom Driver Tip': 'Agrega una propina personalizada',
    'Type your custom driver tip': 'Escribe la propina personalizada',
    'Your tip will be fully given to the driver': 'Tu propina se irá completamente al repartidor',
    'Driver Tip': 'Propina',
    'How much would you recommend us?': '¿Qué tanto nos recomendarías? ',
    'Will be delivered in 45-60 min': 'Será entregado en 45-60 min',

    'Invoice': 'Factura',
    'Generate your invoice automatically': 'Genera tu factura automáticamente',
    'Was not possible to find the order': 'No fue posible encontrar la orden',
    'Order Id': 'Orden Id',
    'Payment Method': 'Método de pago',
    'CREDIT_CARD': 'Tarjeta',
    'CARD_ON_DELIVERY': 'Pagar con tarjeta en la entrega',
    'CASH': 'Efectivo',
    'TRANSFER': 'Transferencia',
    'Tax Info': 'Datos Fiscales',
    'Business Name': 'Razón Social',
    'Regime Fiscal': 'Régimen Fiscal',
    'Street': 'Calle',
    '# External': '# Exterior',
    '# Internal': '# Interior',
    'Postal Code': 'Código postal',
    'State': 'Estado',
    'City': 'Municipio/Delegación',
    'Suburb': 'Colonia',
    'Card Type': 'Tipo de Tarjeta',
    'CREDIT': 'Crédito',
    'DEBIT': 'Débito',
    'MULTIPLE': 'Multiple',
    'Generate': 'Generar',
    'This order has already been invoiced': 'Está orden ya ha sido facturada',
    'The invoice has been generated successfully and will be sent to your email': 'La factura ha sido generada excitosamente y se enviará a tu email',
    'Download the @trackInAppBannerBrandName app to receive notifications.': 'Descarga la app de @trackInAppBannerBrandName para recibir notificaciones.',
    'Free download': 'Descarga gratis',
    'Order protection': 'Protección de orden',

    '601': '601 - General de Ley Personas Morales',
    '603': '603 - Personas Morales con Fines no Lucrativos',
    '605': '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios',
    '606': '606 - Arrendamiento',
    '612': '612 - Personas Físicas con Actividades Empresariales y Profesionales',
    '620': '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos',
    '621': '621 - Incorporación Fiscal',
    '622': '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras',
    '623': '623 - Opcional para Grupos de Sociedades',
    '624': '624 - Coordinados',
    '625': '625 - Régimen de las Actividades Empresariales con ingresos a través de Plataformas Tecnológicas',
    '626': '626 - Régimen Simplificado de Confianza',
    '628': '628 - Hidrocarburos',
    '629': '629 - De los Regímenes Fiscales Preferentes y de las Empresas Multinacionales',
    '630': '630 - Enajenación de acciones en bolsa de valores',
    'Notice of Privacy (Privacy Policy) - Users': 'Aviso de Privacidad (Política de privacidad) - Usuarios',
    'Terms and Conditions of Use of the “Ambit” Platform': 'Términos Y Condiciones De Uso De Plataforma “Ambit”',
    'Terms and Conditions': 'Términos y condiciones',
    'Privacy Policy': 'Políticas de privacidad',
    'By continuing, your accepting the ': 'Al continuar, estás aceptando las ',
    ' and ': ' y ',
    'Make your next order easier @url': 'Facilita tu siguiente pedido @url',
    'Enter the table number': 'Ingrese el número de la mesa',
    'The limit of cash payments is @amount': 'El limite de pagos por efectivo es de @amount',

    'Search...': 'Buscar...',
    'Continue as guest': 'Continuar como invitado',
    'Add name': 'Agregar nombre',
    'Add mobile number': 'Agregar número de teléfono',
    'To add a promo code you need to sign in': 'Para agregar un código de promoción necesitas iniciar sesión',

    'OrderTypes.TABLE_ORDER': 'Comer aquí',
    'OrderTypes.SELF_SERVICE_ORDER': 'Comer aquí',
    'OrderTypes.TAKE_AWAY_ORDER': 'Para llevar',
    'OrderTypes.DELIVERY_ORDER': 'Envío a domicilio',
    'OrderTypes.ROOM_SERVICE_ORDER': 'Servicio de habitación',

    'Amount to pay': 'Total a pagar',
    'My Order': 'Mi Orden',
    'Continue': 'Continuar',
    'By continuing, you are accepting the': 'Al continuar, estás aceptando las',
    'Privacy Policy and Terms and Conditions.': ' Políticas de Privacidad y Términos y Condiciones.',

    'Remember to add the house or building number': 'Recuerda agregar el número de casa o edificio',
    'No delivery address': 'Sin dirección de entrega',
    'No Promo Code': 'Sin código promocional',
    'Remove': 'Eliminar',
    'Summary': 'Resumen',
    'No table': 'Sin mesa',
    'No payment method': 'Sin método de pago',
    'Program your order': 'Programa tu orden',
    'No guest name': 'Sin nombre de invitado',
    'No phone number': 'Sin número de teléfono',
    'Make order': 'Hacer pedido',

    'Order Confirmed': 'Orden confirmada',
    'Your $@total payment was realized successfully': 'Tu pago de $@total se realizó de manera correcta',

    'DriverArrivesAtStoreTimes.DEFAULT': '45-60',
    'DriverArrivesAtStoreTimes.TIME_30_40_MIN': '60-70',
    'DriverArrivesAtStoreTimes.TIME_40_50_MIN': '70-80',
    'DriverArrivesAtStoreTimes.TIME_50_60_MIN': '80-90',

    'Email will be sent to the address you registered with the provided phone number': 'Enviaremos un código a tu correo registrado con este número',

    'Sent to the restaurant': 'Enviado al negocio',
    'Delivery man on the way': 'Repartidor en camino',
    'Delivery person arrived at store': 'El repartidor llego a la tienda',

    'Your order was created and will be accepted shortly': 'Tu pedido fue creado y será aceptado a la brevedad',
    'Your order was accepted and is being prepared': 'Tu pedido fue aceptado y está siendo preparado',
    'A delivery person has been assigned to your order': 'Se ha asignado un repartidor a tu pedido',
    'Your delivery person has arrived at the store and is waiting for your order': 'El repartidor ha llegado a la tienda y se encuentra esperando tu pedido',
    'Prepare the table, your order is on its way': 'Prepara la mesa, tu pedido está en camino',
    'The restaurant refused your order': 'El negocio rechazó tu pedido',
    'Your order was cancelled': 'Tu pedido fue cancelado',
    'Payment link cancelled': 'Link de pago cancelado',
    'Payment link refunded': 'Link de pago reembolsado',
    'Payment successful!': 'Pago exitoso!',
    'Pay @amount': 'Pagar @amount',

    'An unexpected error occurred': 'Ocurrió un error inesperado',
    'Payment succeeded': 'Pago exitoso',
    'Processing payment': 'Procesando pago',

    'You need to validate your card': 'Necesitas validar tu tarjeta',
    'We have placed a hold of less than $20 on your card. Please confirm if you have received a notification/SMS from your bank or log in to your online banking to identify the amount withheld and enter it here. Fraud prevention protocol.':
        'Hemos realizado una retención menor de $20 en tu tarjeta. Confirma si te ha llegado una notificación/SMS de tu banco o ingresa a tu banca en línea, para identificar el monto retenido e ingresarlo aquí. Protocolo para prevención de fraudes.',
    'A validation charge has been made on your card between $10.00 and $20.00. Please enter the amount that was charged on @cardNumber at "@date".':
        'Se ha realizado un cargo de validación en tu tarjeta por un monto entre $10.00 y $20.00. Por favor, ingresa la cantidad que se cargó en la tarjeta @cardNumber en la fecha "@date".',
    'Why is it necessary?': '¿Por qué es necesario?',
    'The validation of your card is a method to ensure that no one else is using your card fraudulently, thus protecting you from unrecognized charges.':
        'La validación de tu tarjeta es un método para garantizar que nadie más esté utilizando tu tarjeta de forma fraudulenta, y así te protegemos de cargos no reconocidos.',
    'Are you sure you want to validate this card?': '¿Estás seguro de que quieres validar esta tarjeta?',
    'For your own safety we need to verify this card belongs to you.\nWe will charge a random amount less than $20 on your card that you will need to send to us to verify that you are the owner of this card. The amount will be refunded and only used for verification.':
        'Por su propia seguridad, necesitamos verificar que esta tarjeta le pertenece.\nCobraremos una cantidad aleatoria de menos de $20 en su tarjeta que deberá enviarnos para verificar que usted es el propietario de esta tarjeta. El importe se reembolsará y solo se utilizará para la verificación.',
    'You have tried to validate card @cardNumber too many times. Please try again or contact support.':
        'Haz intentado validar la tarjeta @cardNumber demasiadas veces. Vuelve a intentarlo o ponte en contacto con el soporte.',
    'Entered amount do not match amount charged.': 'El monto ingresado no coincide con el monto cobrado.',
    'BLOCKED': 'BLOQUEADA',
    'NOT VALIDATED': 'NO VALIDADA',
    'Failed to add card. Make sure you have at least $20 on your cards. Otherwise, you can try to contact your bank to solve this problem or try another card.':
        'No se pudo agregar la tarjeta. Asegúrate de tener al menos $20 en tu tarjeta. En caso contrario, puedes intentar ponerte en contacto con tu banco para solucionar este problema o intentar con otra tarjeta.',
    'Failed to add card. You can try to contact your bank to solve this problem or try another card.':
        'No se pudo agregar la tarjeta. Puedes intentar ponerte en contacto con tu banco para solucionar este problema o intentar con otra tarjeta.',
    'Failed to create payment needed for the card validation. Make sure you have at least $20 on your cards and try again. Also make sure you have internet connection. If the problem persists try another card.':
        'No se pudo crear el pago necesario para la validación de la tarjeta. Asegúrate de tener al menos $20 en tu tarjeta e inténtalo de nuevo. También asegúrese de tener conexión a Internet. Si el problema persiste prueba con otra tarjeta',
    'You didn’t receive the code? Try again in': 'Deberías recibir tu código de verificación en',
    'in @secondsLeft seconds': 'en @secondsLeft segundos',
    'Sub modifier groups': 'Sub modificadores',
    'Payment information': 'Información de pago',
    'Selected card': 'Tarjeta seleccionada',
    'This field is required': 'Este campo es requerido',
    'Expiry date': 'Fecha de caducidad',
    'Card number': 'Número de tarjeta',
    'Invalid card number': 'Número de tarjeta invalido',
    'Card number is required': 'El número de tarjeta es requerido',
    'Expiry is required': 'La fecha de caducidad es requerida',
    'Expiry must be in format MM/YY': 'La fecha de caducidad debe estar en formato MM/YY',
    'CVC is required': 'El CVC es requerido',
    'Card blocked': 'Tarjeta bloqueada',
    'Understood': 'Entendido',
    'You didn’t receive the code?': '¿No recibiste el código?',
    'Try again': 'Intenta de nuevo',
    'Send again': 'Enviar nuevamente',
    'Failed to load google maps, please accept get your location.': 'No se pudo mostrar el mapa, por favor acepta que podamos obtener tu ubicación',
    'Payment successful': 'Pago exitoso',
    'here': 'aquí',
    'Leave us your comments ': 'Dejanos tus comentarios ',
    'Order details #@shortOrderId': 'Detalles de la órden #@shortOrderId',
    'We could not found this payment link, check that the link is correct or ask the person who sent it to you to create a new one.':
        'No pudimos encontrar este link de pago, revisa que el link sea correcto o pide a la persona que te lo mandó que genere uno nuevo.',
    'Pay': 'Pagar',
    'We are processing your payment': 'Estamos procesando tu pago',
    'Please wait until the transactions is completed': 'Por favor espera a que la transaction se complete',
    'There was an error while processing your payment, please try again.': 'Hubo un error al procesar tu pago, por favor intenta otra vez.',
    'In case you need invoice, ': 'En caso de necesitar factura',
    'click here': 'click aquí',
    'Delivery cost': 'Costo de envío',
    'We have refunded your payment successfully. If you have any question or you need more information please contact us.':
        'Hemos reembolsado tu pago con éxito. Si tienes alguna pregunta o necesitas más información, no dudes en contactarnos.',
    'Maximum of delivery reached: @maximum': 'Máximo de envío alcanzado: @maximum',
    'Maximum of delivery reached': 'Máximo de envío alcanzado',
    'To guarantee the quality of your shipment and that the product arrives without problem, we limit the maximum that the delivery person can carry. If you want you can continue creating another order when finished':
        'Para garantizar la calidad de tu envío y que llegue el producto sin problema, limitamos el máximo que puede llevar el repartidor. Si deseas puedes continuar creando otra orden al terminar',
    'We were unable to process the payment with the selected card. Please check that you have sufficient funds':
        'No pudimos procesar el pago con la tarjeta seleccionada. Por favor revisa que tenga fondos suficientes',
    'Maximum order amount': 'Monto máximo de pedido',
    'Edit Address': 'Editar dirección',
    'Free quantity': 'Cantidad gratuita',
    'If it exceeds the free quantity, the higher value items will be added': 'Si supera la cantidad gratuita, se sumarán los ítems de mayor valor',

    'UnitsOfMeasurement.KILOGRAM': 'kg',
    'UnitsOfMeasurement.GRAM': 'gr',
    'UnitsOfMeasurement.LITRE': 'lt',
    'UnitsOfMeasurement.MILLILITER': 'ml',
    'UnitsOfMeasurement.CURRENCY': 'Moneda',

    'Quantity': 'Cantidad',
    'Room service': 'Servicio a cuarto',
    'Room number': 'Número de cuarto',
    'Room number @room': 'Número de habitación @room',

    'Make sure to select the flag of your country': 'Asegurate de seleccionar la bandera de tu país',
    'Ups!': '¡Ups!',
    'Something went wrong, we did not find what you are looking for.': 'Algo salió mal, no encontramos la página que buscas.',
    'Not found content image': 'Imagen de contenido no encontrado ',

    'Example: @data': 'Ejemplo: @data',
    'Enter your tax information': 'Ingresa tus datos fiscales',
    'Zip Code': 'Código postal',
    'Successful billing': 'Facturación exitosa',
    'The invoice was sent to': 'La factura fue enviada a',
    'We will charge a random amount less than $20 on your card that you will need to send to us to verify that you are the owner of this card. The amount will be refunded and only used for verification.':
        'Cobraremos una cantidad aleatoria de menos de $20 en su tarjeta que deberá enviarnos para verificar que usted es el propietario de esta tarjeta. El importe se reembolsará y solo se utilizará para la verificación.',
    'Payment on delivery': 'Pago a la entrega',
    'Pay online': 'Pago en línea',
    'There are no payment methods configured': 'No hay métodos de pago configurados',
    '+ New Card': 'Nueva tarjeta',

    'We are working so that you can soon order from @name online': 'Estamos trabajando para que pronto puedas ordenar a @name en línea',
    '@name is temporarily offline': '@name está temporalmente sin servicio',
    'Temporarily offline': 'Temporalmente sin servicio',
    'Temporarily without delivery': 'Temporalmente sin entrega',
    '@name is temporarily without delivery': '@name está temporalmente sin entrega',
    'Temporarily without delivery service': 'Temporalmente sin servicio de entrega ',
    'The online store is without delivery service': 'La tienda en línea está sin servicio de entrega',
    'The store is currently closed, but you can still see the menu and check the hours.': 'En estos momentos la tienda se encuentra cerrada, pero aún puedes ver el menú y consultar los horarios.',

    'You will receive @totalCashback in cashback': 'Recibirás @totalCashback en cashback',
    'You will receive @totalCashback in cashback if you pay online': 'Recibirás @totalCashback en cashback si pagas en línea',
    'You will receive @totalCashback in cashback upon completing the order': 'Recibiras @totalCashback en cashback al completar la orden',
    'Your discount code': 'Tú código de descuento',
    'Copy': 'Copiar',

    'Business': 'Negocio',
    'The online store is without service': 'La tienda online está sin servicio.',

    'Icon': 'Ícono',
    'Chain': 'Cadena',
    'Cellphone': 'Teléfono móvil',
    'Arrows with opposite directions': 'Flechas con direcciones opuestas',
    'Wallet': 'Billetera',
    'Door hanger': 'Colgador de puerta',
    'Sheet': 'Hoja',
    'Save': 'Guardar',
    'TipTypes.AMOUNT': 'Cantidad',
    'TipTypes.PERCENT': 'Porcentaje',
    'Tip type': 'Tipo de propina',
    'Transfer': 'Transferencia',
    'Transfer Icon': 'Icono de Transferencia',
    'Cash Icon': 'Icono de Efectivo',
    'Save time, sign up now!': '¡Ahorra tiempo, registrate ya!',
    'Your next visit will be simpler and faster, you only have to create a password': 'Tu próxima visita sera más simple y rápida, solo necesitas crear tu contraseña',
    'Confirm password': 'Confirmar contraseña',
    'User': 'Usuario',
    'At another time': 'En otro momento',
    'Sign up': 'Registrar',
    'Create account': 'Crear cuenta',
    'Phone': 'Teléfono',
    'Password does not match': 'La contraseña no coincide',
    'Password': 'Contraseña',
    'Create password': 'Crear contraseña',
    'Set your new password': 'Establece tu nueva contraseña',
    'Password should have at least one number': 'La contraseña debe tener al menos un número',
    'Password should have at least 8 characters': 'La contraseña debe tener al menos 8 caracteres',
    'Password number validation passed': 'Validación de número en contraseña exitosa',
    'Password number validation failed': 'Validación de número en contraseña fallo',
    'Password length validation passed': 'Validación de longitud en contraseña exitosa',
    'Password length validation failed': 'Validación de longitud en contraseña fallo',
    'Use code': 'Usar código',
    'Use SMS': 'Usar SMS',
    'Use password': 'Usar contraseña',
    'Forgot your password?': '¿Olvidaste tu contraseña?',
    'User does not exists, try to sign in with code.': 'El usuario no existe, intenta iniciar sesión con código.',
    'only @orderTypes': 'solo @orderTypes',
    'Service fee': 'Costo de servicio',
    'Add a service fee': 'Agrega un costo de servicio',
    'Type': 'Tipo',
    'Service fee is fixed': 'El costo de servicio es fijo',
    'Tip is fixed': 'La propina es fija',

    'Minimum sales quantity': 'Cantidad mínima de venta',
    'The minimum sales quantity is @minimumSalesQuantity': 'La cantidad mínima de venta es @minimumSalesQuantity',

    'View menu': 'Ver menú',
    'Coming soon': 'Próximamente',

    'Order now': 'Ordenar ahora',
    'Delivery time': 'Tiempo de entrega',
    'Now': 'Ahora',

    'With technology from': 'Con tecnología de',
    'Electronic billing powered by': 'Facturación electrónica con tecnología de',
    'Welcome': 'Te damos la bienvenida',
    'Create an account': 'Crea una cuenta',
    'Confirm your code': 'Confirma tu código',
    'Register': 'Regístrate',
    'Login': 'Iniciar sesión',
    'Send SMS': 'Enviar SMS',
    'User already registered': 'Usuario ya registrado',
    'Unregistered user': 'Usuario no registrado',
    'Are you a new user?': '¿Eres nuevo usuario?',
    'Do you already have an account?': '¿Ya tienes una cuenta?',
    'Sign in': 'Inicia sesión',
    'Continue with email': 'Continuar con correo electrónico',
    'Enter your phone number and choose if you want to continue by SMS or with a password': 'Introduce tu número de teléfono y elige si deseas continuar por SMS o con contraseña',
    'Enter your phone number to begin registration and continue at your order': 'Escribe tu número de teléfono para comenzar el registro y continuar a tu orden',
    "Make sure you select your country's flag": 'Asegúrate de seleccionar la bandera de tu país',
    'Enter your password to continue': 'Introduce tu contraseña para continuar',
    'Your next visit will be simpler and faster': 'Tu próxima visita será más simple y rápida',

    'Rate your experience': 'Califica tu experiencia',
    'Service fee (@serviceFee%)': 'Costo de servicio (@serviceFee%)',
    'Cash fee': 'Comisión efectivo',
    '@discount OFF': '@discount DE DESCUENTO',
    '@discount off': '@discount de descuento',
    '@discount in credits': '@discount en créditos',
    'Promo code added': 'Código de promoción agregado',
    'Free selected products': 'Productos seleccionados gratis',
    'in total': 'en el total',
    'in @menuItemName': 'en @menuItemName',
    'in selected products': 'en productos seleccionados',
    'Promo code valid for @menuItemNames.': 'Código de promoción valido para @menuItemNames.',
    'Maximum discount @maxDiscountAmount.': 'Descuento máximo @maxDiscountAmount',
    'Minimum order @minOrderAmount.': 'Pedido mínimo @minOrderAmount.',
    'Valid for @orderTypes': 'Válido para @orderTypes',
    'Available promo codes': 'Códigos de promoción disponibles',
    'Only valid for your first order.': 'Solo válido para tu primera orden',

    'Contact by Whatsapp': 'Contacta por Whatsapp',
    'Contact @phoneNumber': 'Contacta al @phoneNumber',

    'Phone icon': 'Icono de telefono',
    'WhatsApp icon': 'Icono de WhatsApp',
    'Headphone icon': 'Icono de audifonos',

    'MexicanPaymentMethodCodes.CASH': '01 - Efectivo',
    'MexicanPaymentMethodCodes.NOMINAL_CHECK': '02 - Cheque nominativo',
    'MexicanPaymentMethodCodes.ELECTRONIC_FUNDS_TRANSFER': '03 - Transferencia electronica de fondos',
    'MexicanPaymentMethodCodes.CREDIT_CARD': '04 - Tarjeta de crédito',
    'MexicanPaymentMethodCodes.ELECTRONIC_WALLET': '05 - Monedero electrónico',
    'MexicanPaymentMethodCodes.ELECTRONIC_MONEY': '06 - Dinero electrónico',
    'MexicanPaymentMethodCodes.DEBIT_CARD': '28 - Tarjeta de débito',
    'MexicanPaymentMethodCodes.SERVICE_CARD': '29 - Tarjeta de servicios',
    'MexicanPaymentMethodCodes.TO_DEFINE': '99 - Por definir',

    'MexicanPaymentMethodCodes.01': '01 - Efectivo',
    'MexicanPaymentMethodCodes.02': '02 - Cheque nominativo',
    'MexicanPaymentMethodCodes.03': '03 - Transferencia electronica de fondos',
    'MexicanPaymentMethodCodes.04': '04 - Tarjeta de crédito',
    'MexicanPaymentMethodCodes.05': '05 - Monedero electrónico',
    'MexicanPaymentMethodCodes.06': '06 - Dinero electrónico',
    'MexicanPaymentMethodCodes.28': '28 - Tarjeta de débito',
    'MexicanPaymentMethodCodes.29': '29 - Tarjeta de servicios',
    'MexicanPaymentMethodCodes.99': '99 - Por definir',
    'Valid for @paymentMethods': 'Válido para @paymentMethods',
};
