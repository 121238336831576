import { CartItemVm } from 'src/types/CartItemVm';
import type { AppVm } from 'src/types/StateVm';
import { createPaymentDistribution } from 'src/utils/payment/createPaymentDistribution';
import { isPromoCodeApplicable } from 'src/utils/promoCode/isPromoCodeApplicable';
import { requireValue } from 'src/utils/require/requireValue';

export function refreshPaymentState(app: AppVm): void {
    if (!app.cartItems.length || !app.restaurant) {
        delete app.payment;
        return;
    }

    const payment = createPaymentDistribution({
        restaurantId: requireValue(app.restaurant).restaurantId,
        country: requireValue(app.restaurant).country,
        externalDelivery: requireValue(app.restaurant).externalDelivery,
        credits: app.credits,
        gifts: app.gifts,
        promotions: app.promotions,
        promoCode: app.promoCode,
        orderType: app.orderType,
        paymentMethod: app.paymentMethod,
        pickupTime: app.pickupTime,
        orderItems: app.cartItems,
        restaurantTimeZone: requireValue(app.restaurant).timeZone,
        deliveryEstimate: app.deliveryEstimate,
        roomServiceFixedDeliveryCost: app.roomServiceFixedDeliveryCost,
        useLetsEatCredits: app.useLetsEatCredits,
        driverTip: app.driverTip,
        numberOfOrders: app.numberOfOrders,
        addTaxToTotalEnabled: requireValue(app.restaurant).addTaxToTotalEnabled,
        restaurantServiceFeeEnabled: requireValue(app.restaurant).restaurantServiceFeeEnabled,
        restaurantServiceFeeRate: requireValue(app.restaurant).restaurantServiceFeeRate,
        taxRate: requireValue(app.restaurant).taxRate,
        pideDirectoProtectionCost: app.restaurant.pideDirectoProtectionCost,
        pideDirectoProtectionMinimumOrderAmount: app.restaurant.pideDirectoProtectionMinimumOrderAmount,
    });

    const usableLetsEatCredits = createPaymentDistribution({
        restaurantId: requireValue(app.restaurant).restaurantId,
        country: requireValue(app.restaurant).country,
        credits: app.credits,
        gifts: app.gifts,
        promoCode: app.promoCode,
        orderType: app.orderType,
        paymentMethod: app.paymentMethod,
        pickupTime: app.pickupTime,
        orderItems: app.cartItems,
        restaurantTimeZone: requireValue(app.restaurant).timeZone,
        deliveryEstimate: app.deliveryEstimate,
        useLetsEatCredits: true,
        numberOfOrders: app.numberOfOrders,
        addTaxToTotalEnabled: requireValue(app.restaurant).addTaxToTotalEnabled,
        taxRate: requireValue(app.restaurant).taxRate,
    }).usedLetsEatCredits;

    if (app.checkoutDialog.open && app.promoCode) {
        const menuItemIds = app.cartItems?.map((orderItem: CartItemVm) => orderItem?.menuItemId);
        const isPromoCodeValid = isPromoCodeApplicable({
            promoCode: app.promoCode,
            orderType: app.orderType,
            paymentMethod: app.paymentMethod,
            numberOfOrders: app.numberOfOrders,
            amount: payment.total,
            customerDeliveryCost: payment.customerDeliveryCost,
            menuItemIds,
        });
        if (!isPromoCodeValid) app.promoCode = undefined;
    }

    app.payment = {
        subtotal: payment.subtotal,
        subtotalAfterDiscount: payment.subtotalAfterDiscount,
        customerDeliveryCost: payment.customerDeliveryCost,
        productDiscount: payment.productDiscount,
        promotionsDiscount: payment.promotionsDiscount,
        tax: payment.tax,
        taxRate: payment.taxRate,
        deliveryCashHandlingFee: payment.deliveryCashHandlingFee,
        pideDirectoProtectionCost: payment.pideDirectoProtectionCost,
        promoCodeSubtotal: payment.promoCodeSubtotal,
        promoCodeDiscount: payment.promoCodeDiscount,
        promoCodeCredits: payment.promoCodeCredits,
        usedPromotions: payment.usedPromotions,
        serviceFee: payment.serviceFee,
        serviceFeeRate: payment.serviceFeeRate,
        restaurantServiceFee: payment.restaurantServiceFee,
        restaurantServiceFeeRate: payment.restaurantServiceFeeRate,
        usableLetsEatCredits,
        usedCredits: payment.usedCredits,
        letsEatCredits: payment.usedLetsEatCredits,
        companyCredits: payment.usedCompanyCredits,
        total: payment.total,
    };
}
